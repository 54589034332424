import { RouterDataLinks } from '@campaign-portal/components-library';

import { CanDeactivateGuard } from '@helpers/can-deactivate/can-deactivate.guard';
import { AP_PERMISSIONS } from '@helpers/types/permissions';

export const routerLinks: RouterDataLinks = {
	dashboard: {
		path: 'dashboard',
		loadComponent: () =>
			import('../../pages/dashboard/dashboard.component')
				.then(m => m.DashboardComponent),
		data: {
			permissions: [],
			title: 'dashboard.title',
			icon: 'icon-dashboard-square',
			animationState: 'DashboardComponent',
			overflow: true
		}
	},
	portals: {
		path: 'portals',
		loadComponent: () =>
			import('../../pages/portals/portals.component')
				.then(m => m.PortalsComponent),
		data: {
			permissions: [AP_PERMISSIONS.PORTALS_R],
			title: 'portals.title',
			icon: 'icon-portals',
			animationState: 'PortalsComponent',
			overflow: true
		}
	},
	editPortal: {
		path: 'edit-portals',
		loadComponent: () =>
			import('../../pages/portals/edit-portal/edit-portal.component')
				.then(m => m.EditPortalComponent),
		canDeactivate: [
			CanDeactivateGuard
		],
		data: {
			permissions: [AP_PERMISSIONS.PORTALS_R],
			title: '',
			animationState: 'EditPortalComponent',
			backRoute: true,
			hiddenMenu: true,
			overflow: true
		}
	},
	partners: {
		path: 'partners',
		loadComponent: () =>
			import('../../pages/partners/partners.component')
				.then(m => m.PartnersComponent),
		data: {
			permissions: [],
			title: 'partners.title',
			icon: 'icon-partner',
			animationState: 'PartnersComponent'
		}
	},
	subscriptions: {
		path: 'subscriptions',
		data: {
			permissions: [AP_PERMISSIONS.SUBSCR_R],
			title: 'subscriptions.title',
			icon: 'icon-subscriptions',
			animationState: 'SubscriptionsComponent'
		},
		children: [
			{
				path: '',
				loadComponent: () =>
					import('../../pages/subscriptions/subscriptions.component')
						.then(m => m.SubscriptionsComponent),
				data: {
					permissions: [],
					title: 'subscriptions.title',
					animationState: 'SubscriptionsComponent',
					hiddenMenu: true
				}
			},
			{
				path: 'subscription',
				loadComponent: () =>
					import('../../pages/subscriptions/subscriptions-list/subscription-details/subscription-details.component')
						.then(m => m.SubscriptionDetailsComponent),
				canDeactivate: [
					CanDeactivateGuard
				],
				data: {
					permissions: [],
					title: '',
					animationState: 'SubscriptionDetailsComponent',
					overflow: true,
					backRoute: true,
					hiddenMenu: true
				}
			}
		]
	},
	ratesImport: {
		path: 'rates-import',
		loadComponent: () =>
			import('../components/rates-import/rates-import.component')
				.then(m => m.RatesImportComponent),
		canDeactivate: [
			CanDeactivateGuard
		],
		data: {
			permissions: [AP_PERMISSIONS.SUBSCR_E, AP_PERMISSIONS.V_SUBSCR_E],
			permissionComparison: 'some',
			title: 'rates.import-title',
			animationState: 'RatesImportComponent',
			backRoute: true,
			hiddenMenu: true
		}
	},
	rates: {
		path: 'rates',
		loadComponent: () =>
			import('../../pages/vendors/vendors.component')
				.then(m => m.VendorsComponent),
		data: {
			permissions: [AP_PERMISSIONS.V_SUBSCR_R, AP_PERMISSIONS.ENDPOINTS_R],
			permissionComparison: 'some',
			title: 'rates.title',
			icon: 'icon-rate',
			animationState: 'VendorRatesComponent'
		}
	},
	urlShortener: {
		path: 'url-shortener',
		loadComponent: () =>
			import('../../pages/url-shortener/url-shortener.component')
				.then(m => m.UrlShortenerComponent),
		data: {
			permissions: [AP_PERMISSIONS.URL_SHORTENER_E],
			title: 'urlShortener.title',
			icon: 'icon-link',
			animationState: 'UrlShortenerComponent'
		}
	},
	finance: {
		path: 'finance',
		loadComponent: () =>
			import('../../pages/finance/finance.component')
				.then(m => m.FinanceComponent),
		data: {
			permissions: [AP_PERMISSIONS.PAYMENTS_R],
			title: 'finance.title',
			icon: 'icon-purchase',
			animationState: 'FinanceComponent'
		}
	},
	senders: {
		path: 'senders',
		loadComponent: () =>
			import('../../pages/senders/senders.component')
				.then(m => m.SendersComponent),
		data: {
			permissions: [AP_PERMISSIONS.SENDERS_R],
			title: 'senders.title',
			icon: 'icon-sender-ID',
			animationState: 'SendersComponent'
		}
	},
	statistics: {
		path: 'statistics',
		loadComponent: () =>
			import('../../pages/statistics/statistics.component')
				.then(m => m.StatisticsComponent),
		data: {
			permissions: [AP_PERMISSIONS.STATS_R],
			title: 'statistics.title',
			icon: 'icon-statistics',
			animationState: 'StatisticsComponent'
		}
	},
	users: {
		path: 'users',
		loadComponent: () =>
			import('../../pages/users/users.component')
				.then(m => m.UsersComponent),
		data: {
			permissions: [AP_PERMISSIONS.USERS_R],
			title: 'users.title',
			icon: 'icon-contact',
			animationState: 'UsersComponent'
		}
	},
	settings: {
		path: 'settings',
		data: {
			permissions: [],
			title: 'settings.title',
			icon: 'icon-settings',
			animationState: 'SettingsComponent'
		},
		children: [
			{
				path: '',
				redirectTo: 'payment-systems',
				pathMatch: 'full',
				data: {
					permissions: [],
					title: 'settings.title',
					icon: 'icon-settings',
					animationState: 'SettingsComponent',
					hiddenMenu: true
				}
			},
			{
				path: 'payment-systems',
				loadComponent: () =>
					import('../../pages/settings/payment-systems/payment-systems.component')
						.then(m => m.PaymentSystemsComponent),
				data: {
					permissions: [],
					title: 'payment-systems.title',
					animationState: 'PaymentSystemsComponent'
				}
			},
			{
				path: 'mccmnc',
				loadComponent: () =>
					import('../../pages/settings/reference-book/reference-book.component')
						.then(m => m.ReferenceBookComponent),
				data: {
					permissions: [],
					title: 'mccmnc.title',
					animationState: 'MccmncComponent'
				}
			},
			{
				path: 'contract-companies',
				loadComponent: () =>
					import('../../pages/settings/contract-companies/contract-companies.component')
						.then(m => m.ContractCompaniesComponent),
				data: {
					permissions: [],
					title: 'contract-companies.title',
					animationState: 'ContractCompaniesComponent'
				}
			}
		]
	},
	accountSettings: {
		path: 'account-settings',
		loadComponent: () =>
			import('@campaign-portal/components-library')
				.then(m => m.AlarisAccountSettingsComponent),
		data: {
			permissions: [],
			title: 'accountSettings.title',
			icon: '',
			animationState: 'AccountSettingsComponent',
			hiddenMenu: true
		}
	}
};
